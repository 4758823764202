<template>
  <div>
    <div class="container">
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        style="margin-top: 20px"
        label-position="left"
      >
        <el-row>
          <el-form-item :label="ddlLabel">
            <el-cascader
              style="width: 260px"
              :show-all-levels="false"
              :placeholder="ddlDesc"
              :options="ddlStation"
              :props="props"
              collapse-tags
              v-model="form.stationIdArray"
              clearable
            ></el-cascader>
          </el-form-item>
         

          <el-form-item v-if="typeId==0" label="媒体类型"  style="margin-left:30px;">
            <el-select v-model="form.typeArray" clearable  placeholder="请选择媒体类型">
              <el-option
                  v-for="item in mediaTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="关键字"  >
            <el-input  v-model="form.key" placeholder="请输入媒体编号关键字"></el-input>
          </el-form-item>
          <el-button
            style="margin-left: 60px; height: 20px"
            type="primary"
            @click="onMediaQuery"
            >检索</el-button>
        </el-row>
          
        <!-- </el-row> -->
      </el-form>
    </div>
    <div>
      <el-divider>待选资源</el-divider>
      <el-table
        ref="tableObj" 
        :data="SearchMediaList"
        border
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        style="width: 100%; margin-top: 10px; font-size: x-small"
        :row-key="getRowKey" stripe 
		    @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" reserve-selection width="55"></el-table-column>
        <el-table-column prop="station" align="center" sortable label="车站/所"></el-table-column>
        <el-table-column v-if="dataType==1" prop="mediaCode" align="center" sortable label="媒体编码"></el-table-column>
        <el-table-column prop="position" align="center" sortable label="位置"></el-table-column>
        <el-table-column v-if="dataType==2" prop="name" align="center" sortable label="套餐名称"></el-table-column>
        <el-table-column v-if="dataType==2" prop="medias" align="center"   sortable label="媒体资源">
            
        </el-table-column>

        <el-table-column prop="typeName" align="center" sortable label="媒体形式"></el-table-column>       
        
        <el-table-column v-if="dataType==1" prop="address" align="center" sortable label="具体位置"></el-table-column>
        <el-table-column v-if="dataType==2" prop="totalCount" align="center" sortable label="数量"></el-table-column>
        <!-- <el-table-column v-if="dataType==1" prop="areaPercent" align="center" sortable label="面积占比"></el-table-column> -->
        <el-table-column v-if="dataType==1" prop="adSize" align="center" sortable label="广告尺寸(m*m)"></el-table-column>       
        <el-table-column v-if="dataType==1" prop="adArea" align="center" sortable label="广告面积(㎡)"></el-table-column>

      </el-table>
    </div>
  </div>
</template>

<script>
import { number } from 'echarts';
import { mapGetters } from "vuex";

export default {
  name: "baseform",
  props: {
			typeId: {
				type: Number,
				default: 0  //0代表不限制类型
			},
      dataType:{
        type:Number,
        default:1
      }
  },
  model: {
		prop: 'form',
		event: 'change'
  },
  async created() {
      this.clearSearchList();
  },

  computed: {
    ...mapGetters([
      "mediaTypeList",
      "positionList",
      "GetGroupList",
      "areaStationList",
      'juDuanSuoList',
      "GetStationPositionMediaList",
      "GetProductMediaList",
    ]),
  },
  data() {
    return {
      form: {
        stationIdArray: [],
        ptArray: [],
        selectedList:[],
        typeArray:[],
        key:'',
      },
      SearchMediaList: [],
      ddlStation:[],

      props: { multiple: true },
      ddlLabel:'车站',
      ddlDesc:'请选择车站',
    };
  },

  async mounted() {
    if(this.typeId===4){
      await this.$store.dispatch("operate/juDuanSuoList");
      this.ddlStation = this.juDuanSuoList;
      this.ddlLabel='局段所'
      this.ddlDesc='请选择局段所';
    }else{
      await this.$store.dispatch("operate/areaStationList");
      this.ddlStation = this.areaStationList;
    }
    
    // console.log('areaStationList',this.areaStationList)
    await this.$store.dispatch("operate/positionList");

    
    
    var index = this.ddlStation.findIndex(item =>{
　　　　　if(item.value=='全国'){
　　　　　　　　return true
　　　　　}
　　})
// 　　this.ddlStation.splice(index,1)

  },

  methods: {
    clearSearchList(){
      this.SearchMediaList=[];
    },

    async onMediaQuery() {        
        console.log('this.typeId',this.typeId)
        const {stationIdArray,ptArray,typeArray,key} =  this.form;        
        if (stationIdArray.length<1){
            this.$message.success('请选择车站');
            return;
        }
        var posIds='';
        if (ptArray.length>0)
            posIds = ptArray.join(',');

        // var typeIds='';
        // if (typeArray.length>0)
        //     typeIds = typeArray.join(',');

        var typeIds=typeArray;
        var sid = '';//stationId[2];
        stationIdArray.forEach(element => {
          if (sid==''){
            sid=element[2];
          }else{
            sid=sid+','+element[2];
          }
        });
        if (this.typeId!==0){
          typeIds=this.typeId+'';
        }
        // console.log('stationId',sid)
        // console.log('bb',typeArray)
        // console.log('aa',typeIds)
        if (this.dataType==1){
          await this.$store.dispatch('operate/GetStationPositionMediaList',{stationId:sid,typeIds,posIds,key}).then(()=>{
              this.SearchMediaList = this.GetStationPositionMediaList;
              // console.log('this.$refs.tableObj',this.$refs.tableObj)
              this.$refs.tableObj.clearSelection();
          })
        }else{
          // console.log('aa',typeIds)
          await this.$store.dispatch('operate/GetProductMediaList',{stationId:sid,typeIds,posIds,key}).then(()=>{
              this.SearchMediaList = this.GetProductMediaList;
              // console.log('this.$refs.tableObj',this.$refs.tableObj)
              this.$refs.tableObj.clearSelection();
          })
        }

    },

    handleSelectionChange(val) {				
      this.form.selectedList = val;				
      this.SelectEdEmit();
    },

    SelectEdEmit() {
      const obj = {...this.form};
      console.log('obj',obj);
      this.$emit('change', obj);
    },

    getRowKey(row) {
      return row.mediaId;
    },

    open() {
      console.log("do open");
      this.$nextTick(() => {
        //  执行echarts方法
      });
    },
  },
};
</script>

<style scoped>
</style>