<template>
    <div>        
        <div class="container">
            <div  width="100%">
                <!-- <div class="section">
                    <span slot="label" class="title"><i class="el-icon-date"></i> 套餐管理</span>
                </div> -->
                <div style="display:flex;flex-direction: row-reverse;">
                    
                    <!-- <el-button type="primary"  style="margin-right:10px;">资源素材上传</el-button> -->
                    <el-button type="primary" @click="onOpenUpload" style="margin:0px 10px;">资源数据导入</el-button>
                    <el-button @click="newGroup"  icon="el-icon-plus" type="primary">新建</el-button>
                </div>

                <el-form  ref="form" :model="searchForm" label-width="80px" style="margin-top:20px;" label-position="left">
                    <el-row>
                        <el-form-item label="省份/车站">
                            <el-cascader 
                                    :show-all-levels="false"
                                    placeholder="请选择省份/车站"
                                    :options="areaStationList"
                                    :props="props"
                                    collapse-tags
                                    v-model="searchForm.stationId"  
                                    clearable                       
                            ></el-cascader>
                        </el-form-item>
                        <el-form-item label="媒体类型"  style="margin-left:30px;">
                            <el-select v-model="searchForm.typeArr" clearable multiple placeholder="请选择媒体类型">
                            <el-option
                                v-for="item in mediaTypeListWithLC"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="关键字"  style="margin-left:30px;">
                                <el-input  v-model="searchForm.key" placeholder="请输入关键字"></el-input>
                        </el-form-item>
                        <el-button style="margin-left:30px;height: 20px;" type="primary" @click="onGroupQuery">检索</el-button>
                        <el-button type="primary"  style="margin-right:10px;" @click="onExporMedia">资源数据导出</el-button>
                        <el-button type="text"  @click="onTemplateClick" style="margin-right:10px;">资源数据导入模板下载</el-button>
                    </el-row>
                </el-form>
                <el-table
                    :data="tableGroupList.slice((currentPage-1)*pageSize,currentPage*pageSize)"
                          
                            border
                            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                            style="width: 100%;margin-top: 10px;font-size: x-small;">		
                            <el-table-column prop="area" align="center"  width="80"  sortable label="大区"></el-table-column>
                            <el-table-column prop="province" align="center"  width="60"  sortable label="省份"></el-table-column>
                            <el-table-column prop="station" align="center"  width="123"  sortable label="车站">
                                <template v-slot="scope">
                                    <el-button type="text" @click="openStation(scope.row.station)">{{scope.row.station}}</el-button>                                    
                                </template>
                            </el-table-column>	                            
                            <!-- <el-table-column prop="position" align="center"  width="80"  sortable label="位置"></el-table-column> -->
                            <el-table-column prop="typeName" align="center"  width="70"  sortable label="资源类型"></el-table-column>
                            <el-table-column prop="position" align="center"   width="120" sortable label="位置"></el-table-column>
                            <el-table-column prop="name" align="center"   width="120" sortable label="套餐名"></el-table-column>
                            <el-table-column prop="totalCount" align="center"  width="50" sortable label="资源数量"></el-table-column>
                            <!-- <el-table-column prop="cost15" align="center"  sortable label="15秒月刊例"></el-table-column>
                            <el-table-column prop="cost10" align="center"  sortable label="10秒月刊例"></el-table-column>
                            <el-table-column prop="cost" align="center"  sortable label="月刊例"></el-table-column> -->
                            <el-table-column prop="medias" align="center"   width="120" sortable label="刊例(万元)">
                                    <template v-slot="scope">
                                        <div v-if="scope.row.cost && scope.row.cost>0">
                                            {{scope.row.cost}}
                                        </div>
                                        <div v-else>
                                            <div v-if="scope.row.cost15">{{scope.row.cost15}}(/15秒)</div>
                                            <div v-if="scope.row.cost10">{{scope.row.cost10}}(/10秒)</div>
                                        </div>
                                    </template>
                                </el-table-column>
                            <el-table-column prop="medias" align="center"   sortable label="媒体资源(尺寸:m*m 面积:㎡)">
                                <template v-slot="scope">
                                    <div v-if="scope.row.collapse==-1"><!-- 无需折叠 -->
                                        <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                            <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                                            <div v-else>{{item.mediaCode}}</div>
                                        </div>
                                    </div>
                                    <div v-else-if="scope.row.collapse==1"><!-- 折叠状态 -->
                                        <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                            <div v-if="index<4">
                                                <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                                                <div v-else>{{item.mediaCode}}</div>                                                
                                            </div>
                                        </div>
                                        <i class="el-icon-arrow-down" style="cursor:pointer;" @click="onCollapse(scope.row)"></i>
                                    </div>
                                    <div v-else><!-- 展开状态 -->
                                        <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                            <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                                            <div v-else>{{item.mediaCode}}</div>                                            
                                        </div>
                                        <i class="el-icon-arrow-up" style="cursor:pointer;" @click="onCollapse(scope.row)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                            
                            <el-table-column prop="remark" align="center"  sortable label="说明"></el-table-column>
                            <el-table-column prop="userName" align="center"  width="70" sortable label="创建人"></el-table-column>   
                            <el-table-column prop="rowTime" align="center"  width="150" sortable label="创建时间"></el-table-column> 
                            <!-- <el-table-column prop="id" align="center" label="状态" width="100">
                                <template v-slot="scope">
                                    <el-button v-if="scope.row.isDel==true" type="warning" plain @click="onSetStatus(scope.row)" circle>停用</el-button>
                                    <el-button v-else type="success" plain @click="onSetStatus(scope.row)" circle>正常</el-button>
                                </template>
                            </el-table-column> -->
                            <el-table-column prop="medias" align="center" width="80px;"  sortable label="状态">
                                <template v-slot="scope">
                                    <span :style="scope.row.isDel?'color:red':''">{{scope.row.isDel?'已删除':'正常'}}</span>                                    
                                </template>
                            </el-table-column>
                            <el-table-column prop="id" align="center" label="操作" width="80px;">
                                <template v-slot="scope">
                                    <!-- <i class="el-icon-edit" color="blue" @click="onEdit(scope.row)"></i> -->
                                    <!-- <i class="el-icon-delete" color="red" @click="onSetStatus(scope.row)"></i> -->
                                    <!-- <el-button type="primary" style="height: 20px;width:50px;" @click="onEdit(scope.row)">编辑</el-button> -->
                                    <div style="display:flex;">
                                        <el-tooltip content="编辑" placement="top" effect="light">
                                            <el-button type="text" icon="el-icon-edit"  @click="onEdit(scope.row)"></el-button>
                                        </el-tooltip>
                                        <el-tooltip content="删除" placement="top" effect="light">
                                            <el-button type="text" icon="el-icon-delete"  @click="onDelete(scope.row)"></el-button>
                                        </el-tooltip>
                                        <!-- <el-button type="warning" style="height: 20px;width:50px;"  @click="onSetStatus(scope.row)">切换状态</el-button> -->
                                        <!-- <el-tooltip content="切换状态" placement="bottom" effect="light">
                                            <el-button type="warning" style="margin-left: 10px;" icon="el-icon-star-off" @click="onSetStatus(scope.row)" circle></el-button>
                                        </el-tooltip> -->                                        
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column prop="id" align="center" label="开关" width="120">
                                <template v-slot="scope">
                                    <div style="display:flex;">
                                        <el-switch v-model="scope.row.isValid" active-text="开" inactive-text="关" @click="onSetStatus(scope.row)"></el-switch>
                                    </div>
                                </template>
                            </el-table-column> -->
                </el-table>   
                <el-pagination
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                        :current-page="currentPage"
                                        :page-sizes="[20,50, 100]"
                                        :page-size="pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="tableGroupList.length">
                </el-pagination>            

                <div>
                    <el-dialog title="套餐设置" v-model="resultDialogVisible" width="60%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>

                        <div style="margin-top:-55px;">
                            
                            <el-form  ref="form" :model="groupForm" label-width="120px" style="margin-top:20px;" label-position="left">
                                <el-row>
                                    <el-form-item label="套餐名称" >
                                        <el-input  v-model="groupForm.groupName" style="width:205px;" placeholder="请输入套餐名称"></el-input>
                                    </el-form-item>
                                    <el-form-item label="媒体类型"  style="margin-left:30px;">
                                        <el-select @change="typeChanged" v-model="groupForm.typeId"  placeholder="请选择媒体类型">
                                            <el-option
                                            v-for="item in mediaTypeListWithLC"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-row>
                                <el-row v-if="isLed">
                                    <el-form-item label="15秒刊例" >
                                        <el-input  v-model="groupForm.cost15" style="width:205px;" placeholder="请输入15秒刊例"></el-input>
                                        <span class="beizhu">万元/每月/每日100次</span>
                                    </el-form-item>
                                </el-row>
                                <el-row>
                                    <el-form-item  v-if="isLed" label="10秒刊例" >
                                        <el-input  v-model="groupForm.cost10" style="width:205px;" placeholder="请输入10秒刊例"></el-input>
                                        <span class="beizhu">万元/每月/每日100次</span>
                                    </el-form-item>
                                    <el-form-item  v-if="isLed==false" label="刊例" >
                                        <el-input  v-model="groupForm.cost" style="width:205px;" placeholder="请输入刊例"></el-input>
                                        <span class="beizhu">万元/每月</span>
                                    </el-form-item>
                                </el-row>
                                <el-row>
                                    <el-form-item label="资源等级">
                                        <el-select v-model="groupForm.mediaGrade"  placeholder="请选择">
                                            <el-option
                                            v-for="item in mediaGradeList"
                                            :key="item.name"
                                            :label="item.name"
                                            :value="item.name">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-row>
                                <el-row>
                                    <el-form-item label="补充说明" style="width: 100%;">
                                        <el-input v-model="groupForm.remark" type="textarea" autocomplete="off" :rows="3"
                                                                style="width: 500px;"></el-input>
                                    </el-form-item>
                                </el-row>
                                <el-row>
                                    <el-divider>资源</el-divider>    
                                    <div style="display:flex;flex-direction:row-reverse;width: 100%;">
                                        <el-button type="text" @click="addDialogVisible=true">添加资源</el-button>
                                    </div>
                                    <el-table
                                        :data="MediaList"
                                        border
                                        :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                                        style="width: 100%;margin-top: 10px;font-size: x-small;">	                                        
                                        <el-table-column prop="station" align="center" sortable label="车站"></el-table-column>
                                        <el-table-column prop="position" align="center" sortable label="位置"></el-table-column>
                                        <el-table-column prop="mediaCode" align="center" sortable label="媒体编码">
                                            <template v-slot="scope">
                                                <span v-if="scope.row.isNew==1" style="color:red" >{{scope.row.mediaCode}}</span>
                                                <span v-else >{{scope.row.mediaCode}}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="address" align="center" sortable label="具体位置"></el-table-column>
                                        <el-table-column prop="count" align="center" sortable label="数量"></el-table-column>
                                        <!-- <el-table-column prop="areaPercent" align="center" sortable label="面积占比"></el-table-column> -->
                                        <el-table-column prop="adSize" align="center" sortable label="广告尺寸(m*m)"></el-table-column>       
                                        <el-table-column prop="adArea" align="center" sortable label="广告面积(㎡)"></el-table-column>                                                                     
                                        <el-table-column prop="id" align="center" label="操作" width="100">
                                            <template v-slot="scope">
                                                <i class="el-icon-delete" color="red" @click="onRemove(scope.$index)"></i>
                                            </template>
                                        </el-table-column>
                                    </el-table> 
                                    
                                    <el-dialog title="添加资源" v-if="addDialogVisible" v-model="addDialogVisible" width="60%" 
                                        :modal-append-to-body='false'
                                        :close-on-click-modal='false'
                                         @close='closeAddDialog'
                                        append-to-body>
                                        <select-pure-media ref="newMediaObj" :type-id="groupForm.typeId"/>
                                        <!-- <span class="dialog-footer">
                                            <el-button type="primary" @click="onAdd">加入</el-button>
                                        </span> -->
                                        <div style="display:flex;flex-direction:row-reverse;width: 100%;margin-top:20px;">                                            
                                            <el-button style="margin-left:20px;" type="primary" @click="onAdd">加入</el-button>
                                            <el-button @click="addDialogVisible=false">取消</el-button>                                            
                                        </div>
                                    </el-dialog>
                                </el-row>
                            </el-form>
                        </div>   
                        <template #footer>
                            <span class="dialog-footer">
                                <el-button @click="resultDialogVisible = false">关闭</el-button>
                                <el-button type="primary" @click="saveEdit">保存</el-button>
                            </span>
                        </template>
                    </el-dialog>

                    <el-dialog title="车站介绍" v-model="viewStationVisible" width="80%"  @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="viewStationVisible"
                                    append-to-body>
                            <div style="height:2800px;">
                                <station @close="viewStationVisible=false" :station="station"/>
                            </div>
                    </el-dialog>

                    <el-dialog title="站点图片" v-model="viewImageVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
                        <div>
                            <el-carousel :interval="4000" type="card" height="400px">
                                <el-carousel-item v-for="item in imageList" :key="item">
                                <!-- <h3 class="medium">{{ item }}</h3> -->
                                <el-image :src="item" >
                                    <!-- :preview-src-list="imageList" -->
                                    <div class="image-slot">
                                        加载中<span class="dot">...</span>
                                    </div>
                                </el-image>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="button-footer">                      
                            <span >
                                <el-button @click="viewImageVisible = false">关闭</el-button>
                            </span>                    
                        </div>
                    </el-dialog>
                
                    <el-dialog title="导入资源" v-model="importDialogVisible" width="40%"
                            :modal-append-to-body='false'
                            :close-on-click-modal='false'
                            @close="importDialogVisible = false" 
                            append-to-body>
                        <el-form :model="importForm" inline style="margin-top: 30px;">
                            <el-row>
                                <el-form-item label="选择套餐资源Excel文件">
                                        <el-upload
                                                class="upload-demo"
                                                ref="upload"
                                                action="string"
                                                :http-request="uploadFile"
                                                :limit="1"
                                                :auto-upload="true">
                                                <!-- <el-button slot="trigger" size="small" type="primary">选取文件</el-button> -->
                                                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">选择文件</el-button>
                                        </el-upload>
                                        <!-- <my-upload-file-form-item label="媒体资源Excel文件" :limit="1"  :subDir="'upload'" :file-list="curItem.excelArr" v-model="curItem.imgArr"/>                                     -->
                                </el-form-item>
                            </el-row>                                         
                        </el-form>

                        <div slot="footer" style="display:flex;justify-content:center">
                            <el-button @click="importDialogVisible = false">关闭</el-button>
                            <el-button v-if="logId!==0" style="margin-left:20px;" type="primary" @click="onSaveImport">保存</el-button>
                        </div>
            
                    </el-dialog>
                </div>

                
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectPureMedia from './SelectPureMedia.vue';
// import {dealDeleteOperation} from "@/utils/tools";
import {getToken} from "@/utils/auth";
import {SaveGroup,SetGroupStatus,DelGroup,GetExportMediaList} from "@/api/operate";
import {DownloadTemplateFile,ImportDataToDB} from "@/api/updown";
import Station from '@/views/Business/Station';

export default {
    name: 'baseform',
    components:{SelectPureMedia,Station},
    computed: {...mapGetters(['mediaTypeList','positionList','GetGroupList','areaStationList','GetGroupDetail','ImportDataToTmp'])},
    data() {
        return {
          resultDialogVisible:false,
          addDialogVisible:false,
          searchForm:{
              stationId:[],
              typeArr:[],
              key:'',
          },
          props: { multiple: true },
          groupForm:{
              groupId:'',
              groupName:'',
              typeId:1,
              cost15:'',
              cost10:'',
              cost:'',
              remark:'',
              mediaGrade:'',
            //   AddList:'',
          },
           // 当前页
          currentPage: 1,
        // 每页多少条
          pageSize: 50,
          MediaList:[],
          isLed:true,
          

        viewStationVisible:false,
        viewImageVisible:false,
        imageList:[],
        mediaGradeList:[{name:'A'},{name:'B'},{name:'S'},{name:'S+'}],
        tableGroupList:[],

        importDialogVisible:false,
        importForm:{
        
        },
        logId:0,
      };
        
    },
    
    async mounted() {      
      await this.$store.dispatch('operate/areaStationList');
      await this.$store.dispatch('operate/mediaTypeList').then(()=>{
        this.mediaTypeListWithLC=[];
        this.mediaTypeList.forEach(ele => {
            if (ele.value!==4){
                this.mediaTypeListWithLC.push(ele);
            }
        });
        
      });
      await this.$store.dispatch('operate/positionList');
      this.onGroupQuery();
    //   await this.$store.dispatch('operate/GetGroupList',{isAll:1}).then(()=>{
    //     this.tableGroupList = this.GetGroupList;
    //   });      
    },



    methods: {
        async onDelete(item){
            this.$confirm('是否确认删除选中套餐？ 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {    
                        DelGroup({userId:getToken(),groupId:item.groupId});                    
						this.$message.success('删除成功');
                        for(let index in this.tableGroupList){
                            if(this.tableGroupList[index].groupId==item.groupId){
                                this.tableGroupList.splice(index,1);
                            }
                        }                        
					})
        },

        async onExporMedia(){
            const {stationId,key,typeArr} = this.searchForm;
            var stationIds='';
            stationId.forEach(ele => {
                if (stationIds==""){
                    stationIds=ele[2];
                }else{
                    stationIds=stationIds+','+ele[2];
                }
            });
            var typeIds='';
            if (typeArr){
                typeIds=typeArr.join(',');
            }
            typeIds=1;
            if (typeIds==''){
                this.$message.error("请选择");
                return;
            }

            // await this.$store.dispatch('operate/GetGroupList',{stationIds,typeIds,key,isAll:1}).then(()=>{
            //     this.tableGroupList = this.GetGroupList;
            // });      

            await GetExportMediaList({userId:getToken(),stationIds,typeId:typeIds,key})
        },

        async onSaveImport(){
            await ImportDataToDB({userId:getToken(),fileType:1, logId:this.logId}).then(()=>{                
                this.$message.success('入库成功！');
                // return;
                this.$refs.upload.clearFiles();
                this.importDialogVisible = false;
                this.onGroupQuery();
            })
            
        },

        onOpenUpload(){
            const {stationId,key,typeArr} = this.searchForm;
            // console.log('aa',typeArr.length,typeArr[0])
            if (typeArr.length!==1 || typeArr[0]!==1){
                this.$message.success('媒体类型请选择LED');
                return;
            }
            this.logId=0;
            
            this.importDialogVisible = true;
            // 
        },

        async uploadFile(param) {    
            console.log('param',param);
            const formData = new FormData()
            formData.append('fileInfo', param.file);
            const loading = this.$loading({
                lock: true,
                text: '导入中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            await this.$store.dispatch("updown/ImportDataToTmp", {
                userId:getToken(),typeId:1,
                formData
            }).then(res=>{
                loading.close();
                this.$message.success('Excel文件读取成功');
                this.logId=this.ImportDataToTmp[0].logId;
                console.log('logId',this.logId);
                // this.onGroupQuery();
                // this.importDialogVisible=false;
            }).catch(error => {
                loading.close();
            })
            
        },

        submitUpload() {
            this.$refs.upload.submit();
        },


        async onTemplateClick() {
            const {stationId,key,typeArr} = this.searchForm;
            // console.log('aa',typeArr.length,typeArr[0])
            if (typeArr.length!==1 || typeArr[0]!==1){
                this.$message.success('媒体类型请选择LED');
                return;
            }
            await DownloadTemplateFile({userId:getToken(),typeId:1});
        },


        ViewImage(item){
            this.imageList = item;
            this.viewImageVisible=true;
        },

        onCollapse(item){
            console.log('collapse')
            if (item.collapse==1){
                item.collapse=0;
            }else{
                item.collapse=1;
            }
        },

        openStation(station){
            this.station=station;
            this.viewStationVisible=true;
        },

        newGroup(){
            this.groupForm.groupId='';
            this.groupForm.groupName='';
            this.groupForm.typeId=1;
            this.groupForm.cost15='';
            this.groupForm.cost10='';
            this.groupForm.cost='';
            this.groupForm.remark='';
            this.groupForm.mediaGrade='';
            this.MediaList=[];
            this.isLed=true;
            // this.groupForm.AddList=[],
            this.resultDialogVisible = true;
        },

        onRemove(index){
            // console.log('aaa',index)
            this.MediaList.splice(index,1)
        },

        async onEdit(item){
            await this.$store.dispatch('operate/GetGroupDetail',{groupId:item.groupId}).then(()=>{
                this.groupForm.groupId=this.GetGroupDetail[0][0].groupId;
                this.groupForm.groupName=this.GetGroupDetail[0][0].name;
                this.groupForm.typeId=this.GetGroupDetail[0][0].typeId;
                this.groupForm.cost15=this.GetGroupDetail[0][0].cost15;
                this.groupForm.cost10=this.GetGroupDetail[0][0].cost10;
                this.groupForm.cost=this.GetGroupDetail[0][0].cost;
                this.groupForm.mediaGrade = this.GetGroupDetail[0][0].mediaGrade;
                this.groupForm.remark=this.GetGroupDetail[0][0].remark;
                // console.log('this.groupForm',this.groupForm,this.GetGroupDetail)
                this.MediaList=this.GetGroupDetail[1];
                if (this.GetGroupDetail[0][0].typeId==1){
                    this.isLed=true;
                }                    
                else{
                    this.isLed=false;
                }
                this.resultDialogVisible = true;
            })
            
        },

        async onSetStatus(item){
            // await dealDeleteOperation({deletePromise: SetGroupStatus({userId:getToken(), groupId: item.groupId})})
            await SetGroupStatus({userId:getToken(), groupId: item.groupId}).then(()=>{
                this.onGroupQuery();
            })
			// 
        },

        typeChanged(value){
            if(value==1){
                this.isLed=true;
            }else{
                this.isLed=false;
            }
        },
        
        async onGroupQuery(){
            const {stationId,key,typeArr} = this.searchForm;
            // console.log('sss',typeArr)
            // var sid=stationId;
            // if (!sid){
            //     sid=''
            // }else{
            //     sid = sid[1];
            // }
            var stationIds='';
            stationId.forEach(ele => {
                if (stationIds==""){
                    stationIds=ele[2];
                }else{
                    stationIds=stationIds+','+ele[2];
                }
            });
            var typeIds='';
            if (typeArr){
                typeIds=typeArr.join(',');
            }

            await this.$store.dispatch('operate/GetGroupList',{stationIds,typeIds,key,isAll:1}).then(()=>{
                this.tableGroupList = this.GetGroupList;
            });      
        },

        closeAddDialog(){
            this.$refs.newMediaObj.clearSearchList();
        },

        //加入资源
        async onAdd(){
            var newList = this.$refs.newMediaObj.form.selectedList
            if (newList.length==0){
                this.$message.success('请选择资源');
                return;
            }
            // console.log('aa',newList)
            newList.forEach(newl => {
                var exist=false;
                this.MediaList.forEach(oldl => {
                    if (oldl.mediaId==newl.mediaId){
                        exist=true;
                    }
                });
                if (!exist){
                    newl.isNew=1
                    this.MediaList.push(newl);
                }
            });
            this.addDialogVisible=false;
            
        },

        async saveEdit(){
            const {groupId,typeId, groupName:name,cost15,cost10,cost,remark,mediaGrade} = this.groupForm;
            if (!name){
                this.$message.success('请输入套餐名称');
                return;
            }
            
            if (typeId==1 && !cost15){
                this.$message.success('请输入15秒刊例');
                return;
            }
            if (typeId==1 && !cost10){
                this.$message.success('请输入10秒刊例');
                return;
            }
            if (typeId==2 && !cost){
                this.$message.success('请输入刊例');
                return;
            }
            if (!mediaGrade){
                this.$message.success('请选择资源等级');
                return;
            }
            if (this.MediaList.length==0){
                this.$message.success('请加入套餐资源');
                return;
            }
            var mediaIds='';
            var isRightTypeId=true;

            this.MediaList.forEach(element => {
                mediaIds=mediaIds+','+element.mediaId;
                if (element.typeId!==typeId){
                    isRightTypeId=false;
                }
            });
            mediaIds=mediaIds.substring(1);

            if (!isRightTypeId){
                this.$message.error('套餐的媒体类型和所选择的资源媒体类型不一致，请检查');
                return;
            }
            

            // console.log('aa',typeId, name,mediaIds,this.MediaList);
            // return;
            await SaveGroup({userId:getToken(), groupId: groupId,typeId, name,mediaIds,cost15,cost10,cost,remark,mediaGrade}).then(()=>{
                this.onGroupQuery();
                this.resultDialogVisible = false
            })
        },
        

    open(){
        // console.log('do open')
        this.$nextTick(() => {
        //  执行echarts方法
        
        })
      },

      // 每页多少条
            handleSizeChange(val) {
                this.pageSize = val;
            },
            // 当前页
            handleCurrentChange(val) {
                this.currentPage = val;
            },

    }
};
</script>

<style scoped>
.beizhu{
    font-size: 8px;
    color:red;
}
</style>